<template>
  <div id="lay_content_all">
    <lay-header  v-if="!['/index','/index_dw'].includes($route.path)"></lay-header>
    <div class="lay_content_all">
      <div v-if="!['/index','/index_dw'].includes($route.path)" style="width: 100%; height: 5rem">
        <!--高度占位-->
      </div>
      <keep-alive :include="['book_list','bookinfo','index_dwName']">
        <router-view/>
      </keep-alive>
<!--      <router-view/>-->
    </div>

  </div>
</template>
<script>
export default {
  components: {
    LayHeader: (re) => require(["./LayHeader.vue"], re),
  },

  data() {
    return {
      onFooterH: 0,
      showSao: false,
      Flash: false,
    };
  },
  methods: {

  },
  computed: {},
  mounted() {
  },

};
</script>
<style lang="less">
#lay_content_all {
  width: 100%;
  min-height: 100%;
  overflow: hidden;

  .lay_content_all {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1;
    bottom: 0.1rem;

  }
  .saomaxx {
    width: 100%;
    height: 50px;
    z-index: 9999;
    background: var(--themeColor);
    position: absolute;
    bottom: 0;
    display: flex;
    color: #fff;
    font-size: 16px;

    i {
      font-size: 23px;
    }

    .left {
      height: 50px;
      line-height: 50px;
      text-align: center;
      width: 50%;
      border-right: 1px solid #fff;
    }

    .right {
      text-align: center;
      width: 50%;
      line-height: 50px;
      height: 50px;
    }
  }

}
</style>
